import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import CookieConsent from "react-cookie-consent";
import {Row, Col}  from 'react-bootstrap'

export default function Footer() {
    const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          copyright,
        }
      }
    }
  `)
  const { copyright } = data.site.siteMetadata

    return (
        <footer>
            <Row>
                <Col md={12}>
                    <CookieConsent
                            location="bottom"
                            buttonText="Accept"
                            declineButtonText="Decline"
                            cookieName="gatsby-gdpr-google-analytics">
                            We use cookies to provide you with a better experience when accessing our websites. They also help us to analyse how visitors use our websites and this help us to improve the content and design of our websites.
                    </CookieConsent>
                </Col>
                <Col md={12} className="copyright">
                    {copyright}
                </Col>
            </Row>         
        </footer>
    )
}
