import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"

export default function Tags() {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(limit: 2000) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `)

const tag_list = data.allMarkdownRemark.group;
    return (
        <div>
            <h4 className="tags_title">Tags</h4>
            <ul className="tags">
                {tag_list.map(tag => (
                <li key={tag.fieldValue} className="tag">
                    <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                    {tag.fieldValue} ({tag.totalCount})
                    </Link>
                </li>
                ))}
            </ul>
        </div>
    )
}

Tags.propTypes = {
    data: PropTypes.shape({
      allMarkdownRemark: PropTypes.shape({
        group: PropTypes.arrayOf(
          PropTypes.shape({
            fieldValue: PropTypes.string.isRequired,
            totalCount: PropTypes.number.isRequired,
          }).isRequired
        ),
      }),
      site: PropTypes.shape({
        siteMetadata: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }),
      }),
    }),
  }

