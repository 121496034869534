import React from 'react'
import { Link } from 'gatsby'
import * as styles from '../styles/list-item.module.css'

export default function HitArticlePreview({title, link}) {
    return (

        <li className={styles.hit}>
             <Link to={link}>
                        {title}
                </Link> 
        </li>
    )
}