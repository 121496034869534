import React from 'react'
import TopNav from './TopNav'
import Footer from './Footer'
import Tags from './Tags'
import { graphql, useStaticQuery } from 'gatsby'

import HitArticlePreview from '../components/HitArticlePreview'
import '../styles/global.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col}  from 'react-bootstrap'

import Search from './search'

import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
deckDeckGoHighlightElement();

export default function Layout({ children }) {

  const data = useStaticQuery(graphql`
  {
    allMarkdownRemark(
      limit: 25
      filter: {fileAbsolutePath: {glob: "**/examples/aspnet/*"}, frontmatter: {listed: {eq: 1}}}
      sort: {fields: frontmatter___modified, order: DESC}
    ) {
      nodes {
        frontmatter {
          slug
          title
        }
        id
      }
    }
  }
`)


let articles = data.allMarkdownRemark.nodes

    return (
        <Container fluid>
          <TopNav></TopNav>
          <Row>
            <Col xs={12} md={9}>
              <div className="main-content">
                {children}
              </div>            
            </Col>
            <Col xs={12} md={3}>
              <Search></Search>
              <Tags></Tags>
              <br />
              <h4>Get $100 Free, sign up today</h4>
              <a href="https://www.digitalocean.com/?refcode=56f43c33ef42&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge"><img src="https://web-platforms.sfo2.digitaloceanspaces.com/WWW/Badge%203.svg" alt="DigitalOcean Referral Badge" /></a>
              <br /><br /><br />
              <h3>Popular Articles</h3>
              <ul>
              {articles.map(item => (
                  <HitArticlePreview
                  key={item.id}
                  title={item.frontmatter.title}
                  link = {"/" + item.frontmatter.slug}

                  />
                ))}   
                </ul> 
            </Col>
          </Row>
          <Row>
              <Col md={12}>
                <Footer></Footer>
              </Col>
              <a href="https://www.buymeacoffee.com/Kafle " className="float"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" className='buymeacoffee' /></a>

          </Row>     
        </Container>
    )
}
