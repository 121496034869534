import React from 'react'
import { Link } from 'gatsby'
import {Navbar, Nav, NavDropdown}  from 'react-bootstrap';

export default function TopNav() {

    return (
      <Navbar expand="lg">
      <Link className="navbar-brand" to="/"><img src="/images/logo.png" alt="logo" /></Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
        <NavDropdown title=".NET/C#" id="aspnet_drop_down">
            <Link to="/tutorials/asp-dot-net/" className="dropdown-item" >Tutorials</Link>
            <Link to="/howto/csharp/" className="dropdown-item" >How To</Link>
            
        </NavDropdown>
        <NavDropdown title="Laravel/PHP" id="laravel_drop_down">
            <Link to="/tutorials/laravel" className="dropdown-item" >Tutorials</Link>
            <Link to="/howto/php/" className="dropdown-item">How To</Link>
            <Link to="/projects/laravel" className="dropdown-item" >Projects</Link>
        </NavDropdown>
        <NavDropdown title="JavaScript" id="javascript_drop_down">
            <Link to="/tutorials/nodejs" className="dropdown-item" >Nodejs</Link>
        </NavDropdown>
        <NavDropdown title="CMS" id="web_development">
            <Link to="/tutorials/joomla" className="dropdown-item" >Joomla</Link>
            <Link to="/tutorials/wordpress" className="dropdown-item" >Wordpress</Link>
        </NavDropdown>
        <NavDropdown title="Server/Network" id="servernetwork">
            <Link to="/tutorials/pfsense" className="dropdown-item" >PfSense</Link>
        </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      </Navbar>
    )
}
